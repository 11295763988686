import React, {useEffect} from 'react';
import CustomHeader from "../components/CustomHeader/CustomHeader";
import Aux from "../utils/aux";

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Aux>
            <CustomHeader title={'Terms And Conditions'}/>
            <div className='d-flex justify-content-center static-page-bg'>
                <div className='max-width-1000px w-100 font-size-3 py-2'>
                    <div className='font-size-1-4rem font-weight-bold py-2'>CUSTOMER TERM OF USE</div>
                    <p className='text-justify'>Welcome to BayFay. By browsing, downloading, accessing or using this
                        mobile
                        application, you will be subject to the rules, guidelines, policies, terms and conditions
                        applicable
                        to such services and they shall be deemed incorporated into the ToU .We reserve the right, at
                        our
                        sole discretion, to change, modify, add, or remove portions of the ToU at any time and without
                        any
                        prior written notice to you. It is your responsibility to review the ToU periodically for
                        updates/changes. Your continued use of the Website following the changes will mean that you
                        accept
                        and agree to the revisions.</p>
                    <p className='text-justify'>This document is an electronic record in terms of Information Technology
                        Act, 2000 and rules there under as applicable and the amended provisions pertaining to
                        electronic
                        records in various statutes as amended by the Information Technology Act, 2000. This electronic
                        record is generated by a computer system and does not require any physical or digital
                        signatures.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Service:</div>
                    <p className='text-justify'>This is an electronic platform in the form of an electronic marketplace
                        and
                        an intermediary that</p>
                    <p className='text-justify'>(a) Services to facilitate the engagement of buyers and sellers to under
                        a
                        one common platform,</p>
                    <p className='text-justify'>(b) provides a platform to purchase and make you use of available offers
                        provided by various sellers.</p>
                    <p className='text-justify'>All orders are deemed offers for you to purchase our products. We may
                        accept
                        your offer by issuing a confirmatory e-mail and/or mobile confirmation of the products specified
                        in
                        your order. Our acceptance of each such offer is expressly subject to and conditioned on your
                        assent
                        to these terms and conditions of sale. No other terms and conditions will apply.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Eligibility to use:</div>
                    <p className='text-justify'>(a) If you are a minor i.e. under the age of 18 years, you shall not
                        register as a User of the platform and shall not use this platform. You can use it under the
                        guidance of legal Guardian or parents using this platform. We have sole discretion to terminate
                        your
                        account, if you are a minor.</p>
                    <p className='text-justify'>(b) You are prohibited from transferring your Account to another
                        person.</p>
                    <p className='text-justify'>(c) Un-discharged insolvents etc. are not eligible to use the
                        Website.</p>
                    <p className='text-justify'>(d) We reserves the right to refuse access to use the Services offered
                        at
                        the platform to new Users or to terminate access granted to existing Users at any time without
                        according any reasons for doing so.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Third party content</div>
                    <p className='text-justify'>Third party information such as, product catalogues, product description
                        and
                        specifications, lists of dealers, advertisements including videos, images and photographs of the
                        products, links to third party websites and other data from external sources is made available
                        on
                        this platform. The provision of Third Party Content is for general informational purposes only.
                        You
                        acknowledge that the Third Party Content provided to You is obtained from sources believed to be
                        reliable or provided by the sellers in the process of advertising, exhibiting and offering to
                        sell
                        the Products on the platform.</p>
                    <p className='text-justify'>You visit any such external link; You agree to do so at your own risk,
                        responsibility and liability. BayFay makes no warranty or representation regarding, and does not
                        endorse such third party links.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Your Account:</div>
                    <p className='text-justify'>You register as a User by creating an Account in order to avail of the
                        Services. You will be responsible for maintaining the confidentiality and security of the
                        Account
                        Information, and are fully responsible for all activities that occur under Your Account. You
                        should
                        take all necessary steps to ensure that the password is kept confidential and secure. You agree
                        to
                        notify us immediately of any unauthorized use of Your Account Information or any other breach of
                        security, and ensure that You exit from Your Account at the end of each session. We cannot and
                        will
                        not be liable for any loss or damage arising from Your failure to comply with this section. You
                        may
                        be held liable for losses incurred by BayFay or any other user of or visitor to the platform due
                        to
                        authorized or unauthorized use of Your Account as a result of Your failure in keeping Your
                        Account
                        Information secure and confidential.</p>
                    <p className='text-justify'>You agree that information provided by you is untrue, inaccurate, not
                        current or incomplete or We have reasonable grounds to suspect that such information is untrue,
                        inaccurate, not current or incomplete, or not in accordance with the this Terms of Use, We shall
                        have the right to indefinitely suspend or terminate or block access of your membership on the
                        BayFay
                        and refuse to provide You with access to the BayFay.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Electronic Communication</div>
                    <p className='text-justify'>When You use BayFay or send emails or other data, information or
                        communication to us, You agree and understand that You are communicating with Us through
                        electronic
                        records and You consent to receive communications via electronic records from Us periodically
                        and as
                        and when required. We may communicate with you by email or by such other mode of communication,
                        electronic or otherwise.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Charges</div>
                    <p className='text-justify'>BayFay does not levy any fee for browsing or using the app. BayFay
                        reserves
                        the right to change its Fee Policy from time to time. In particular, we may at its sole
                        discretion
                        introduce new services and modify some or all of the existing services offered on the
                        application.
                        In such an event we reserves the right to introduce fees for the new services offered or
                        amend/introduce fees for existing services, as the case may be. Changes to the fee policy shall
                        be
                        posted on the BayFay platform and such changes shall automatically become effective immediately
                        after they are posted on the platform. Unless otherwise stated, all fees shall be quoted in
                        Indian
                        Rupees. You shall be solely responsible for compliance of all applicable laws including those in
                        India for making payments to BayFay.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Access to the app:</div>
                    <p className='text-justify'>We do our level best to maintain a glitch and error free site. Your
                        access
                        to the platform may also be occasionally suspended or restricted to allow for repairs,
                        maintenance,
                        or the introduction of new services at any time without prior notice. We will attempt to limit
                        the
                        frequency and duration of any such suspension or restriction.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Reviews, Comments and Content posted</div>
                    <p className='text-justify'>1. You can post reviews, comments and other content; submit suggestions,
                        ideas, comments, questions or other information, as long as the content is not illegal, obscene,
                        threatening, defamatory, invasive of privacy, infringing of intellectual property rights to
                        otherwise injuries to third party or objectionable and does not consist of or contains software
                        virus, political campaigning, commercial solicitation, mass mailing or any form of spam.</p>
                    <p className='text-justify'>2. You shall not host, display, upload, modify, publish, transmit,
                        update or
                        share any information which belongs to another person.</p>
                    <p className='text-justify'>3. You may not use false email address, impersonate any person or
                        entity, or
                        otherwise mislead as to the origin of a card or other content. BayFay reserves the right (but
                        not
                        the obligation) to remove or edit such content but does not regularly review posted
                        contents.</p>
                    <p className='text-justify'>4. If you do post content or submit material and unless we indicate
                        otherwise, you grant BayFay and its affiliates a non-exclusive, royalty free, perpetual,
                        irrevocable, and fully sub-licensed right to use, reproduce, modify, adapt, publish, translate,
                        create derivative work from, distribute, and display such content throughout the world in any
                        media.
                        You grant Infibeam.com and its affiliate sites and sub-license the right to use the name that
                        you
                        submit in connection with such content if BayFay chooses.</p>
                    <p className='text-justify'>5. You represent and warrant that you own all the rights or otherwise or
                        control all of the rights to the content that you post; that the content is accurate; that the
                        use
                        of the content to supply does not violate this policy and will not cause injury to any person or
                        entity and that you will indemnify BayFay or its affiliate for all claims resulting from the
                        content
                        you supply. BayFay has the right but not the obligation to monitor and edit or remove any
                        activity
                        or content. BayFay takes no responsibility and assumes no liability for any content posted by
                        you or
                        any third party.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Product Description</div>
                    <p className='text-justify'>We attempt to be as accurate as possible. There are circumstance were
                        the
                        digital image of the product may differ from its original image. However, BayFay makes not
                        warranties that the product description and any other content of its site are accurate,
                        complete,
                        reliable, current or error free. The product offered by BayFay itself is not as described and
                        its
                        sole remedy is to return in its unused condition.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Placing an order</div>
                    <p className='text-justify'>When you place consecutive order or separate order, we cannot
                        consolidate
                        the order; a separate delivery fee will be applicable for each order. However, You can order as
                        many
                        product as you required in one single order. We reserve the right to reject your order for any
                        reason at any time. You will receive the refund for BayFay rejected product.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Prices</div>
                    <p className='text-justify'>All prices posted on this platform are subject to change without notice.
                        Prices prevailing at commencement of placing the order will apply. Posted prices do includes all
                        taxes and charges. In case there are any additional charges or taxes the same will be mentioned
                        on
                        the platform.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Condition of the Product on delivery:</div>
                    <p className='text-justify'>Before accepting delivery of any product, kindly ensure that the
                        product’s
                        packaging is not damaged or tampered. If the package is damaged or tampered, we request you to
                        refuse delivery. We assure refund upon such refused delivery or non-delivery. If in case you
                        choose
                        to accept the product, you shall do it at your own risk.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Return</div>
                    <p className='text-justify'>Refund or replacement must be raised within 5-6 calendar days of
                        delivery of
                        the product.</p>
                    <p className='text-justify'>Returns are subject to verification and checks in order to determine the
                        legitimacy of complaint/return. During pick-up, your product shall be checked for the following
                        conditions: Originality of the product, non-usage, Non-damaged.</p>
                    <p className='text-justify'>Keep ready all the requisite items necessary for a smooth returns
                        process —
                        including invoice, original packaging, price tags, freebies, accessories, etc.</p>
                    <p className='text-justify'>Products may be rejected for return if they are not returned as per the
                        conditions mentioned above. Products may also be rejected for return or refund or replacement,
                        if
                        sent directly to the Seller by you without our approval for the same, in which case you waive
                        any
                        and all claims against BayFay.</p>
                    <p className='text-justify'>If the product received does not pass the verification and checks, the
                        product shall be delivered back to you. You are liable for all expenses.</p>
                    <p className='text-justify'>Refund/replacement shall be issued to you only after the product is
                        received
                        and verification of the same.</p>
                    <p className='text-justify'>We shall not be responsible for the products returned by mistake. BayFay
                        or
                        the Seller shall not be accountable for misplacement or replacement of the product and shall not
                        responsible for its delivery back to You.</p>
                    <p className='text-justify'>We reserve a right to impose charges and make the return/replacement
                        product
                        ineligible and also reimburse the expense of delivery and pick-up if we observe that you have a
                        transactional history of repeated returns/replacement.</p>
                    <p className='text-justify'>You are liable to bear the cost if pick up of return withdrawn by
                        you.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Replacement</div>
                    <p className='text-justify'>The product in your order will be replaced with an identical product in
                        case
                        it is damaged, defective or not as described. You are responsible to inform us within 5-6 days
                        from
                        the date of receipt of delivery of the products, in case customer fails to inform BayFay within
                        the
                        stipulated time frame, BayFay reserves the right to accept or reject such request at its
                        discretion.</p>
                    <p className='text-justify'>Replacement is subject to availability of the product (size, color,
                        etc.)
                        and is limited to one request.</p>
                    <p className='text-justify'>In case the replacement product is out of stock, a refund shall be
                        initiated.</p>
                    <p className='text-justify'>If the price of the replacement product is lower than the amount paid by
                        you
                        originally, a partial refund will be issued to you provided.</p>
                    <p className='text-justify'>Products sold as combos/sets cannot be replaced or returned
                        individually</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Defective products</div>
                    <p className='text-justify'>Some products are bound by the brand's specific policies regarding
                        repair,
                        replacement and returns. These policies are binding on you.</p>
                    <p className='text-justify'>You expressly acknowledge that the Seller selling the defective product
                        will
                        be solely responsible to you for any claims that you may have in relation to such defective
                        product
                        and BayFay shall not in any manner be held liable for the same.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>License and platform access:</div>
                    <p className='text-justify'>BayFay grants you a limited license to access and make personal use of
                        this
                        platform and not to download (other than page caching) or modify it, or any portion of it,
                        except
                        with express written consent of BayFay. This license does not include any resale or commercial
                        use
                        of this platform or its content; any collection and use of any product listing, description, or
                        pricing; copying of account information for the benefit of another merchant; or any use of data
                        mining, or similar data gathering and extraction tools. This platform or any portion of this
                        platform may not be reproduced, duplicated, copies, sold, visited, or otherwise exploited for
                        any
                        commercial purpose without express written consent of BayFay. You may not frame or utilize
                        framing
                        technologies to enclose any trademark, logo, or other proprietary information (including images,
                        text, page layout, or form) of BayFay and its affiliates without the express written consent.
                        You
                        may not use any metatags or any other 'hidden text' utilizing BayFay’s name or trademarks
                        without
                        the express written consent of BayFay. Any unauthorized use terminates the permission or license
                        granted by BayFay.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Intellectual Property Right</div>
                    <p className='text-justify'>(a) All editorial content, information, illustrations, photographs
                        artwork
                        and other graphic materials, and logos, names and trade marks on the Mobile Application are
                        protected by copyright laws and/or other laws and/or international treaties, and belong to us
                        and/or
                        our suppliers, as the case may be. These works, logos, graphics, sounds or images may not be
                        copied,
                        reproduced, retransmitted, distributed, disseminated, sold, published, broadcasted or circulated
                        whether in whole or in part, unless expressly permitted by us and/or our suppliers, as the case
                        may
                        be.</p>
                    <p className='text-justify'>(b) Nothing contained on the Mobile Application should be construed as
                        granting by implication, estoppel, or otherwise, any license or right to use any trademark
                        displayed
                        on the Mobile Application without our written permission. Misuse of any trademarks or any other
                        content displayed on the Mobile Application is prohibited.</p>
                    <p className='text-justify'>(c) We will not hesitate to take legal action against any unauthorized
                        usage
                        of our trademarks, name or symbols to preserve and protect its rights in the matter. All rights
                        not
                        expressly granted herein are reserved. Other product and company names mentioned herein may also
                        be
                        the trademarks of their respective owners.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Applicable Law and Jurisdiction</div>
                    <p className='text-justify'>These terms and conditions will be construed only in accordance with the
                        laws of India. In respect of all matters/disputes arising out of, in connection with or in
                        relation
                        to these terms and conditions or any other conditions on this platform, only the competent
                        Courts at
                        Chennai, India shall have jurisdiction, to the exclusion of all other courts.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Disclaimer and exclusion of Liability</div>
                    <p className='text-justify'>THIS PLATFORM IS PROVIDED BY BayFay ON AN "AS IS" AND "AS AVAILABLE"
                        BASIS.
                        BayFay MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                        OPERATION
                        OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUED ON THIS SITE. YOU
                        EXPRESSLY
                        AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.</p>

                    <p className='text-justify'>TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, BayFay DISCLAIMS ALL
                        WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                        MERCHANTABILITY
                        AND FITNESS FOR A PARTICULAR PURPOSE. BayFay DOES NOT WARRANT THAT THE SITE, ITS SERVERS, OR
                        EMAIL
                        SENT FROM BayFay ARE FREE OF VIRUS OR OTHER HARMFUL COMPONENTS. BayFay WILL NOT BE LIABLE FOR
                        ANY
                        DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS PLATFORM, INCLUDING, BUT NOT LIMITED TO DIRECT,
                        INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES.</p>
                </div>
            </div>
        </Aux>
    );
};

export default TermsAndConditions;
