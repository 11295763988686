export const SET_GUEST_TOKEN = 'SET_GUEST_TOKEN';
export const SET_GUEST_USER_ERROR = 'SET_GUEST_USER_ERROR';
export const SET_TOKEN_AND_USERNAME = 'SET_TOKEN_AND_USERNAME';
export const SET_USER_AS_GUEST = 'SET_USER_AS_GUEST';
export const ERROR = 'ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const SET_LOGGED_IN_TOKEN_FROM_STORAGE = 'SET_LOGGED_IN_TOKEN_FROM_STORAGE';

export const SET_SIGN_UP_SUCCESS = 'SET_SIGN_UP_SUCCESS';
export const SET_SIGN_UP_ERROR = 'SET_SIGN_UP_ERROR';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
