export const SET_DELIVERY_GEO_LOCATION = 'SET_DELIVERY_GEO_LOCATION';
export const SET_PRODUCT_SEARCH_GEO_LOCATION = 'SET_PRODUCT_SEARCH_GEO_LOCATION';
export const GEOLOCATION_ERROR_SHOW = 'GEOLOCATION_ERROR_SHOW';
export const SET_DELIVERY_LOCATION_ADDRESS = 'SET_DELIVERY_LOCATION_ADDRESS';
export const SET_PRODUCT_SEARCH_ADDRESS = 'SET_PRODUCT_SEARCH_ADDRESS';

export const API_ERROR = 'API_ERROR';
export const RESET_API_ERROR = 'RESET_API_ERROR';

export const API_SUCCESS = 'API_SUCCESS';
export const RESET_API_SUCCESS = 'RESET_API_SUCCESS';

export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';

export const SHOW_CART_WARNING_DIALOG = 'SHOW_CART_WARNING_DIALOG';
export const HIDE_CART_WARNING_DIALOG = 'HIDE_CART_WARNING_DIALOG';

export const CLOSE_PAYMENT_SCREEN = 'CLOSE_PAYMENT_SCREEN';
export const CLEAR_CLOSE_PAYMENT_SCREEN = 'CLEAR_CLOSE_PAYMENT_SCREEN';

export const HIDE_NO_NEARBY_SHOPS_WARNING = 'HIDE_NO_NEARBY_SHOPS_WARNING';

export const OFFERS_FETCH_SUCCESS = 'OFFERS_FETCH_SUCCESS';


export const SCRATCH_CARD_COUNT_SUCCESS = 'SCRATCH_CARD_COUNT_SUCCESS';
export const SCRATCH_CARD_FETCH_SUCCESS = 'SCRATCH_CARD_FETCH_SUCCESS';
export const SCRATCH_CARD_FETCH_ERROR = 'SCRATCH_CARD_FETCH_ERROR';
