export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_PRODUCT_ALL = 'REMOVE_PRODUCT_ALL';
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';

export const START_DRAGGING_PRODUCT = 'START_DRAGGING_PRODUCT';
export const DROP_PRODUCT_IN_CART = 'DROP_PRODUCT_IN_CART';
export const CANCEL_DRAGGING = 'CANCEL_DRAGGING';

export const CART_FETCH_SUCCESS = 'CART_FETCH_SUCCESS';
export const CLEAR_CART = 'CLEAR_CART';

export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS';
export const ADD_PRODUCT_TO_CART_ERROR = 'ADD_PRODUCT_TO_CART_ERROR';
