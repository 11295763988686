export const BILLING_FETCH_SUCCESS = 'BILLING_FETCH_SUCCESS';
export const BILLING_FETCH_ERROR = 'BILLING_FETCH_ERROR';

export const DELIVERY_TYPES_FETCH_SUCCESS = 'DELIVERY_TYPES_FETCH_SUCCESS';
export const DELIVERY_TYPES_FETCH_ERROR = 'DELIVERY_TYPES_FETCH_ERROR';

export const BAY_FAY_CASH_FETCH_SUCCESS = 'BAY_FAY_CASH_FETCH_SUCCESS';
export const BAY_FAY_CASH_FETCH_ERROR = 'BAY_FAY_CASH_FETCH_ERROR';

export const PROMO_LIST_FETCH_SUCCESS = 'PROMO_LIST_FETCH_SUCCESS';
