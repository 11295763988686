export const GET_TRACKING_ORDERS_SUCCESS = 'GET_TRACKING_ORDERS_SUCCESS';
export const GET_TRACKING_ORDERS_ERROR = 'GET_TRACKING_ORDERS_ERROR';

export const GET_TRACKING_ORDERS_COUNT_SUCCESS = 'GET_TRACKING_ORDERS_COUNT_SUCCESS';
export const GET_TRACKING_ORDERS_COUNT_ERROR = 'GET_TRACKING_ORDERS_COUNT_ERROR';

export const REMOVE_ORDER = 'REMOVE_ORDER';

export const CLEAR_TRACKING_ORDERS = 'CLEAR_TRACKING_ORDERS';

export const GET_PRODUCTS_DETAILS = 'GET_PRODUCTS_DETAILS';
export const CLEAR_PRODUCTS_DETAILS = 'CLEAR_PRODUCTS_DETAILS';

export const SHOP_REVIEW_TITLES_FETCH_SUCCESS = 'SHOP_REVIEW_TITLES_FETCH_SUCCESS';
export const SHOP_REVIEW_TITLES_FETCH_ERROR = 'SHOP_REVIEW_TITLES_FETCH_ERROR';

export const REPLACEMENT_TITLES_FETCH_SUCCESS = 'REPLACEMENT_TITLES_FETCH_SUCCESS';
export const REPLACEMENT_TITLES_FETCH_ERROR = 'REPLACEMENT_TITLES_FETCH_ERROR';

export const CANCEL_ORDER_TITLES_FETCH_SUCCESS = 'CANCEL_ORDER_TITLES_FETCH_SUCCESS';
export const CANCEL_ORDER_TITLES_FETCH_ERROR = 'CANCEL_ORDER_TITLES_FETCH_ERROR';
