export const ORDERS_HISTORY_FETCH_SUCCESS = 'ORDERS_HISTORY_FETCH_SUCCESS';
export const ORDERS_HISTORY_FETCH_ERROR = 'ORDERS_HISTORY_FETCH_ERROR';

export const CLEAR_ORDER_HISTORY = 'CLEAR_ORDER_HISTORY';

export const ORDER_HISTORY_COUNT_SUCCESS = 'ORDER_HISTORY_COUNT_SUCCESS';
export const ORDER_HISTORY_COUNT_ERROR = 'ORDER_HISTORY_COUNT_ERROR';

export const CLEAR_ORDER_HISTORY_PRODUCTS_DETAILS = 'CLEAR_ORDER_HISTORY_PRODUCTS_DETAILS';
export const GET_HISTORY_PRODUCTS_DETAILS = 'GET_HISTORY_PRODUCTS_DETAILS';

export const PRODUCT_REVIEW_TITLES_FETCH_SUCCESS = 'PRODUCT_REVIEW_TITLES_FETCH_SUCCESS';
