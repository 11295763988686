export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const FETCH_PREVIOUS_PRODUCTS_SUCCESS = 'FETCH_PREVIOUS_PRODUCTS_SUCCESS';

export const FETCH_PRODUCTS_COUNT_SUCCESS = 'FETCH_PRODUCTS_COUNT_SUCCESS';
export const FETCH_PRODUCTS_COUNT_ERROR = 'FETCH_PRODUCTS_COUNT_ERROR';

export const CATEGORIES_FETCH_SUCCESS = 'CATEGORIES_FETCH_SUCCESS';
export const CATEGORIES_FETCH_ERROR = 'CATEGORIES_FETCH_ERROR';

export const PRODUCT_DETAILS_TEMPLATE_SUCCESS = 'PRODUCT_DETAILS_TEMPLATE_SUCCESS';
export const PRODUCT_DETAILS_TEMPLATE_ERROR = 'PRODUCT_DETAILS_TEMPLATE_ERROR';


export const REVIEW_FETCH_SUCCESS = 'REVIEW_FETCH_SUCCESS';
export const REVIEW_FETCH_ERROR = 'REVIEW_FETCH_ERROR';

export const REMOVE_FIRST_50_PRODUCTS = 'REMOVE_FIRST_50_PRODUCTS';
export const REMOVE_LAST_50_PRODUCTS = 'REMOVE_LAST_50_PRODUCTS';

export const RESET_SCROLL_CATEGORY = 'RESET_SCROLL_CATEGORY';
