import React, {useEffect} from 'react';
import CustomHeader from "../components/CustomHeader/CustomHeader";
import Aux from "../utils/aux";

const MerchantPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Aux>
            <CustomHeader title={'MERCHANT POLICY'}/>
            <div className='d-flex justify-content-center static-page-bg'>
                <div className='max-width-1000px w-100 font-size-3 py-2'>
                    <div className='font-size-1-4rem font-weight-bold py-2'>TERM OF USE</div>
                    <p className='text-justify'>This document is an electronic record in terms of Information Technology
                        Act, 2000 and rules there under as applicable and the amended provisions pertaining to
                        electronic records in various statutes as amended by the Information Technology Act, 2000. This
                        electronic record is generated by a computer system and does not require any physical or digital
                        signatures.</p>
                    <p className='text-justify'>This document is published in accordance with the provisions of Rule 3
                        (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require
                        publishing the rules and regulations, privacy policy and Terms of Use for access or usage of
                        www.bayfay.com website.</p>
                    <p className='text-justify'>AVAILING THE SERVICE OFFERED BY BayFay SHALL MEAN THAT THE MERCHANT
                        AGREES WITH THE FOLLOWING TERMS AND CONDITIONS MENTIONED AS UNDER IN THE VENDOR AGREEMENT: This
                        Vendor Agreement (hereinafter referred to as the “Agreement”)is entered between the “Vendor”
                        /“Merchant”/ “Seller”(You or the entity you are representing) and BayFay.</p>
                    <p className='text-justify'>This platform is operated and owned by a Pickzy Private Limited a
                        company incorporated under the provisions of Companies Act, 1956, having its registered office
                        located at JVL plaza,Teynampet, Chennai.</p>
                    <p className='text-justify'>For the purpose of these Terms of Use, wherever the context so requires,
                        “Party” or “Parties” or “User” or “you” shall mean any natural or legal person who has agreed to
                        become a buyer or merchant or vendor on the Platform by providing Registration Data while
                        registering on the Platform as a registered user using any computer systems. The term "We",
                        "Us", "Our" shall mean BayFay.</p>
                    <p className='text-justify'>The Agreement shall put forward the terms and condition that shall
                        govern all the online activities carried out by the vendor while using the services and
                        technology provided by BayFay for the term of their association with BayFay.</p>
                    <p className='text-justify'>We reserve the right, at Our sole discretion, to change, modify, add or
                        remove portions of these Terms of Use, at any time without any prior written notice to You. It
                        is Your responsibility to review these Terms of Use periodically for updates / changes. Your
                        continued use of the Website following the posting of changes will mean that You accept and
                        agree to the revisions. As long as You comply with these Terms of Use.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Definitions</div>
                    <p className='text-justify'>1.1.“Merchant/Vendor”:Person or any entity who desires to establish an
                        online store for selling products or services using the space provided BayFay, by accepting this
                        agreement’s terms and condition.</p>
                    <p className='text-justify'>1.2.“Merchant/Vendor Materials”: means all Trademarks, Content,
                        information, data, materials, and other items (excluding Technology) provided or made available
                        by BayFay its Affiliates to BayFay its Affiliates.</p>
                    <p className='text-justify'>1.3.“Merchant/Vendor Order” means an order for a Merchant Product
                        initiated through the website or via customers calling the Telephone Number pursuant to this
                        agreement.</p>
                    <p className='text-justify'>1.4."Catalogue Data"Shall mean any and all the catalogue date or
                        information including but not limited to price, image etc. in the format mutually agreed by the
                        parties in relation to the product provided by Merchant to BayFay.</p>
                    <p className='text-justify'>1.5.“Catalogue management”:Catalogue management shall mean managing the
                        content on the platform which is primarily done using tools like the Content Tool (a module that
                        has been developed to upload / manage product information on the website). The catalogue is
                        uploaded using this tool and any subsequent product or content uploads or modifications are
                        managed using this tool as well.</p>
                    <p className='text-justify'>1.6.“Customer”shall mean any individual, group of individuals, firm,
                        company or any other entity placing an order for the Products on BayFay.</p>
                    <p className='text-justify'>1.7.“E-Commerce Engine”:E-Commerce engine shall mean and include the
                        Back End comprising of a set of seamlessly integrated applications that manage the operations
                        and the various business work flows including, Catalogue Management; Product and Price Updates,
                        Vendor and Delivery Partner Management, Order Processing and Vendor fulfilment.</p>
                    <p className='text-justify'>1.8.“Price”shall mean the cost at which the Products are to be delivered
                        to the Customer inclusive of Shipping charges, applicable taxes if any.</p>
                    <p className='text-justify'>1.9.“Products”shall mean the products of Merchant uploaded at BayFay and
                        such other products that maybe uploaded to the BayFay from time to time for distribution/sales
                        under this Agreement.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Service:</div>
                    <p className='text-justify'>BayFay is engaged in the business of online shopping portal for sale and
                        distribution of various products under various categories to the public at large through its
                        platform BayFay, will provide a panel to Merchant through which Merchant will upload, create
                        their catalogue for sale and distribute their products through BayFay and update order
                        information received in their panels.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Membership enrolment & Eligibility:</div>
                    <p className='text-justify'>(a)As part of the registration, you must provide us with your (or your
                        business') legal name, address, phone number, e-mail address and applicable tax registration
                        details. Merchant should possess a registered shop of his or her own. We may at any time cease
                        providing any or all of the Services at our sole discretion and without notice.</p>
                    <p className='text-justify'>(b) You shall be responsible for maintaining the confidentiality of your
                        Display Name and Password and You shall be responsible for all activities that occur under your
                        Display Name and Password. You agree that if You provide any information that is untrue,
                        inaccurate, not current or incomplete or We have reasonable grounds to suspect that such
                        information is untrue, inaccurate, not current or incomplete, or not in accordance with the this
                        Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of
                        your membership on the BayFay and refuse to provide You with access to the platform.</p>
                    <p className='text-justify'>(c) Use of the platform is available only to persons who can form
                        legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to
                        contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged
                        insolvents etc. are not eligible to use the BayFay.</p>
                    <p className='text-justify'>(d) The term of this Agreement will start on the date of your completed
                        registration for one or more of the Services (the "Effective Date") and continue until
                        terminated by us or you as provided below (the "Term"). We may terminate or suspend this
                        Agreement or any Service immediately in our sole discretion by notice to you for any reason at
                        any time. You may terminate this Agreement or any Service for any reason at any time by the
                        means then specified by BayFay therefore.</p>
                    <p className='text-justify'>(e) Merchant has to provide 3 clear picture of their store ,to display
                        it in BayFay.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Use of platform for Transaction:</div>
                    <p className='text-justify'>(a) All commercial/contractual terms are offered by and agreed to
                        between Buyers and Sellers/vendors/merchant alone. The commercial/contractual terms include
                        without limitation price, shipping costs, payment methods, payment terms, date, period and mode
                        of delivery, warranties related to products and services and after sales services related to
                        products and services. BayFay does not have any control or does not determine or advise or in
                        any way involve itself in the offering or acceptance of such commercial/contractual terms
                        between the Buyers and Sellers/Vendor/Merchant.</p>
                    <p className='text-justify'>(b) BayFay is not responsible for any non-performance or breach of any
                        contract entered into between Buyers and Sellers. BayFay cannot and does not guarantee that the
                        concerned Buyers and/or Sellers will perform any transaction concluded on the Website. BayFay
                        shall not and is not required to mediate or resolve any dispute or disagreement between Buyers
                        and Sellers.</p>
                    <p className='text-justify'>(c) BayFay does not make any representation or Warranty as to specifics
                        (such as quality, value, salability, etc) of the products or services proposed to be sold or
                        offered to be sold or purchased on the Website. BayFay does not implicitly or explicitly support
                        or endorse the sale or purchase of any products or services on the Website. BayFay accepts no
                        liability for any errors or omissions, whether on behalf of itself or third parties. You are
                        advised to independently verify the bona fides of any particular User that You choose to deal
                        with on the platform and use Your best judgment in that behalf.</p>
                    <p className='text-justify'>(d) BayFay does not at any point of time during any transaction between
                        Buyer and Seller on the platform come into or take possession of any of the products or services
                        offered by Seller nor does it at any point gain title to or have any rights or claims over the
                        products or services offered by Seller to Buyer.</p>
                    <p className='text-justify'>(e) At no time shall BayFay hold any right, title or interest over the
                        products nor shall BayFay have any obligations or liabilities in respect of such contract
                        entered into between Buyers and Sellers.</p>
                    <p className='text-justify'>(f) This platform that can be utilized by Users to reach a larger base
                        to buy and sell products or services. BayFay is only providing a platform for communication and
                        it is agreed that the contract for sale of any of the products or services shall be a strictly
                        bipartite contract between the Seller and the Buyer.</p>
                    <p className='text-justify'>(g) BayFay holds the right to reject the poor quality products , if the
                        item description does not match the actual condition of the item, you agree to refund any
                        amounts that you may have received from the Buyer or compensate through appropriate
                        replacement.</p>
                    <p className='text-justify'>(h) From time to time, You shall be responsible for providing
                        information relating to the products or services proposed to be sold by You. In this connection,
                        You undertake that all such information shall be accurate in all respects. You shall not
                        exaggerate or over emphasize the attributes of such products or services so as to mislead other
                        Users in any manner.</p>
                    <p className='text-justify'>(i) Digitized image that accurately depicts only Your Product and does
                        not include any additional logos, text or other markings (and that complies with any BayFay
                        published image guidelines.)</p>
                    <p className='text-justify'>(j) At no time shall BayFay hold any any right, title or interest over
                        the products nor shall BayFay to have any obligations or liabilities in respect of such
                        contract.</p>
                    <p className='text-justify'>(k) BayFay is not responsible for unsatisfactory or delayed performance
                        of services or damages or delays as a result of products which are out of stock, unavailable or
                        back ordered.</p>
                    <p className='text-justify'>(l) You shall independently agree upon the manner and terms and
                        conditions of delivery, payment, insurance etc. with the seller(s) that You transact with.</p>
                    <p className='text-justify'>(m) BayFay shall have the right to remove or edit any content on sales
                        channel in order to increase the sales & demand for the products that in its sole discretion,
                        this shall not affect the seller in any manner.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Relationship of parties:</div>
                    <p className='text-justify'>You and we are independent contractors, and nothing in this Agreement
                        will be construed to create a partnership, joint venture, association of persons, agency,
                        franchise, sales representative, or employment relationship between the parties. BayFay is not
                        an auctioneer, neither is it an intermediary between the customer and the seller. You will have
                        no authority to make or accept any offers or representations on our behalf. You will not make
                        any statement, whether on your site or otherwise, that would contradict anything in this
                        section. This Agreement will not create an exclusive relationship between you and us.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Roles and Responsibility of Merchant/Vendor:
                    </div>
                    <p className='text-justify'>(a) Merchant agrees to be Merchant of BayFay for the sale and
                        distributions of their product through BayFay during the term of this Agreement.</p>
                    <p className='text-justify'>(b) Merchant shall be solely responsible to create and publish and to
                        make live their product catalogue for display and sale of their products through BayFay and
                        Merchant will be responsible for updating / publishing or maintaining such information and will
                        do so using the BayFay platform. BayFay shall not be liable for any catalogue creation, updating
                        and management of Merchant Products catalogue.</p>
                    <p className='text-justify'>(c) Pricing of the Merchant Product will sole prerogative of Merchant in
                        panel provided by BayFay and selling price of the Product (i.e. Product selling price on BayFay)
                        is sole discretion of BayFay.</p>
                    <p className='text-justify'>(d) Merchant shall be solely responsible for pricing update of their
                        products (including discount and promotion information) on merchant panel provided by BayFay.
                        Merchant shall not to change the price of products post to the Buyer(s) order. BayFay shall not
                        be liable in any circumstances for any pricing update of Merchant Product. Until a new price
                        update by Merchant to their catalogue, Merchant will abide by the earlier price provided.</p>
                    <p className='text-justify'>(e)Merchant shall be solely responsible for inventory updates of their
                        products (including discounts and promotions and information about product discontinuation) from
                        time to time for publishing on the BayFay platform. All the Pricing or inventory update shall be
                        done through panel provided by BayFay to Merchant and until a new inventory update is published
                        to BayFay, Merchant will abide by the earlier inventory update. BayFay shall not be liable in
                        any circumstances for any inventory update of Merchant Product on BayFay.</p>
                    <p className='text-justify'>(f) During the Term of this Agreement, Merchant will be solely
                        responsible for Fulfilment of their products to end customer for products (Merchant Products
                        sold through Merchant catalogue on BayFay), Merchant will source, pick, pack and dispatch the
                        products to the designated addresses of end customer. Frequent escalation from the buyer lead to
                        temporary suspension of shop from BayFay.</p>
                    <p className='text-justify'>(g) Merchant will be solely responsible for accepting and processing
                        including pick, pack and returns of their Products and will communicate to customers all
                        necessary information for the return of Products which are sold through BayFay.</p>
                    <p className='text-justify'>(h) BayFay at its sole discretion will refund the entire amount of the
                        product/s to the customer, in case of failure of the Seller to arrange for reverse pick up
                        within 5-6 days from the day customer raises a return request.</p>
                    <p className='text-justify'>(m) In case customer to send the product package return and incurred the
                        logistic charges, then the same will be refunded to end customer and BayFay shall have right to
                        recover such charges from the Seller.</p>
                    <p className='text-justify'>(n) Further Merchant hereby agree to accept the return of their products
                        upto 5-6 days from the date of delivery of the product to end customer.</p>
                    <p className='text-justify'>(o) Non Delivery of Product: If any product is not delivered by Merchant
                        to end-customer then in that case BayFay will not be liable for the same. However, incase if
                        BayFay receive any complaint from end customer regarding non delivery of product, then in that
                        case Merchant agreed to provide all the necessary details including proof of delivery, virtual
                        proof of delivery to BayFay for providing sufficient reason to prove authenticity of delivery of
                        such product.</p>
                    <p className='text-justify'>(p) Merchant should not redirect the buyer to proceed with cash on
                        delivery option. Misusing this platform to make use of our customer, will be considered as
                        breach of contract and we hold rights to terminate your membership and sue a legal action.</p>
                    <p className='text-justify'>(q) Risk of loss: Merchant will bear the risk and will be responsible
                        for all loss of and/or damage of products at all times which are sold through website. Further
                        Merchant will bear the risk and will be responsible for all the loss of and/ or damage of
                        Products at all times which are sold through BayFay.</p>
                    <p className='text-justify'>(r) Insurance: Merchant shall at all-time shall be responsible for
                        insuring the Products and shall take out adequate insurance policies to cover all kind of risks
                        involved. Further the Vendor shall be responsible for making timely payment of the insurance
                        premiums.</p>
                    <p className='text-justify'>(s) Taxes: For avoidance of doubt and notwithstanding anything to the
                        contrary herein, Merchant will indemnify, reimburse and hold BayFay harmless from, for and
                        against any sales, use, gross receipts, excise, franchise, business or other taxes or fees
                        (including penalties, fines or interest thereon) imposed by any government or other taxing
                        authority (collectively, “fulfillment Specific Taxes”) to the extent such taxes or fees are: (a)
                        assessed on BayFay as a result of inventory, packaging, gift wrap and other materials (i) owned
                        by Merchant and/or (ii) sold to customers as contemplated hereunder; and (b) the primary legal
                        obligation of Merchant.</p>
                    <p className='text-justify'>(t) Invoice: Merchant will raise an invoice directly to the end Customer
                        for all ordered successfully fulfilled by the Merchant to end customers.</p>
                    <p className='text-justify'>(u) Merchant shall provide a Self-Attested copy of VAT Certificate, TIN
                        No, PAN Card. & Merchant Bank account details / Cancelled cheque and copy of RTGS mandate
                        form.</p>
                    <p className='text-justify'>(v) Merchant is obligated to sell the product at the listed price to end
                        customer who meet the Seller's terms. By listing an item in a fixed price sale, Merchant
                        represent and warrant to prospective Buyers that Merchant have the right and ability to sell,
                        and that the listing is accurate, current, and complete and is not misleadng or otherwise
                        deceptive.</p>
                    <p className='text-justify'>(s) Before a Seller lists an item, the Seller needs to find out if the
                        item is allowed to be sold on the website and if the type of item is subject to certain
                        restrictions, to avoid potential issues with Seller listing. As a Seller, you are ultimately
                        responsible for making sure that Selling an item is legal under applicable laws. By entering
                        under this Agreement, Merchant represent that it shall not list the products which are
                        prohibited under law and not engaged in following activities:</p>
                    <p className='text-justify'>1. You shall not host, display, upload, modify, publish, transmit,
                        update or share any information which:</p>
                    <p className='text-justify'>(1) belongs to another person and to which You does not have any right
                        to;</p>
                    <p className='text-justify'>(2) is grossly harmful, harassing, blasphemous, defamatory, obscene,
                        pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially,
                        ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or
                        otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing
                        including but not limited to "indecent representation of women" within the meaning of the
                        Indecent Representation of Women (Prohibition) Act, 1986;</p>
                    <p className='text-justify'>(3) is misleading in any way;</p>
                    <p className='text-justify'>(4) is patently offensive to the online community, such as sexually
                        explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or
                        physical harm of any kind against any group or individual;</p>
                    <p className='text-justify'>(5) harasses or advocates harassment of another person;</p>
                    <p className='text-justify'>(6) involves the transmission of "junk mail", "chain letters", or
                        unsolicited mass mailing or "spamming";</p>
                    <p className='text-justify'>(7) promotes illegal activities or conduct that is abusive, threatening,
                        obscene, defamatory or libellous;</p>
                    <p className='text-justify'>(8) infringes upon or violates any third party's rights [including, but
                        not limited to, intellectual property rights, rights of privacy (including without limitation
                        unauthorized disclosure of a person's name, email address, physical address or phone number) or
                        rights of publicity.</p>
                    <p className='text-justify'>(9) violates any law for the time being in force.</p>
                    <p className='text-justify'>(10) shall not be false, inaccurate or misleading;</p>
                    <p className='text-justify'>(aa) shall not, directly or indirectly, offer, attempt to offer, trade
                        or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner
                        under the provisions of any applicable law, rule, regulation or guideline for the time being in
                        force.</p>
                    <p className='text-justify'>(ab) shall not create liability for Us or cause Us to lose (in whole or
                        in part) the services of Our internet service provider ("ISPs") or other suppliers;</p>
                    <p className='text-justify'>Violations of these Policies may result in account suspension.</p>
                    <p className='text-justify'>Brand Name Use & content posted:</p>
                    <p className='text-justify'>Merchant is not permitted to include any brand names or company logos in
                        their listings other than the specific brand name authorized by the original manufacturer to be
                        used for products being sold by Merchant under a particular listing.</p>
                    <p className='text-justify'>All text,graphics, user interfaces, visual interfaces, photographs,
                        trademarks, logos, sounds, music and artwork (collectively, "Content"), is a third party user
                        generated content and BayFay has no control over such third party user generated content as
                        BayFay is merely an intermediary for the purposes of this Terms of Use.</p>
                    <p className='text-justify'>Except as expressly provided in these Terms of Use, no part of the
                        platform and no Content may be copied, reproduced, republished, uploaded, posted, publicly
                        displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to
                        any other computer, server, Website or other medium for publication or distribution or for any
                        commercial enterprise, without BayFay express prior written consent.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Merchant Order Cancellation:</div>
                    <ol>
                        <li>You can't cancel the orders from the Seller App once it is received.</li>
                        <li>
                            You can cancel the orders by contacting the BayFay support team, and Cancellation charges may apply to the similar circumstances below;
                            <ul>
                                <li>Stocks unavailability</li>
                                <li>Customer requested to cancel the order due to delayed delivery</li>
                                <li>Delivery unavailability from Merchant end</li>
                            </ul>
                        </li>
                        <li>
                            In case of cancellation, you won't be charged any Cancellation Fee if it is by factors only attributable to the Customer listed below
                            <ul>
                                <li>Wrong delivery address.</li>
                                <li>If the delivery person couldn't reach the Customer by phone or email at the time of delivery.</li>
                                <li>Unavailability of proper information or direction for the delivery location from Customer.</li>
                            </ul>
                        </li>
                        <li>BayFay has the right to terminate or take any actions against the Merchant/Shop according to BayFay policies, in case of continuous Cancellation of Orders under Merchant attributable circumstances.</li>
                    </ol>

                    <div className='font-size-1rem font-weight-bold py-2'>License:</div>
                    <p className='text-justify'>You grant us a royalty-free, non-exclusive, worldwide, irrevocable right
                        and license during the Term and for as long thereafter as you are permitted to grant the said
                        license under applicable Law to use, reproduce, perform, display (public communication),
                        distribute, adapt, modify, re-format, create and exploit derivative works of, and otherwise
                        commercially or non-commercially exploit in any manner, any and all of Your Materials, and to
                        sublicense the foregoing rights to our Affiliates and operators of BayFay Associated Properties;
                        provided, however, that we will not alter any of Your Trademarks from the form provided by you
                        (except to re-size trademarks to the extent necessary for presentation, so long as the relative
                        proportions of such trademarks remain the same) and will comply with your removal requests as to
                        specific uses of Your Trademarks (provided you are unable to do so using the standard
                        functionality made available to you via the applicable BayFay or Services); provided further,
                        however, that nothing in this Agreement will prevent or impair our right to use Your Materials
                        without your consent to the extent that such use is allowable without a license from you or your
                        Affiliates under applicable Law.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Privacy:</div>
                    <p className='text-justify'>We view protection of your privacy as a very important principle. We
                        understand clearly that You and Your Personal Information is one of Our most important assets.
                        We store and process Your Information including any sensitive financial information collected
                        (as defined under the Information Technology Act, 2000), if any, on computers that may be
                        protected by physical as well as reasonable technological security measures and procedures in
                        accordance with Information Technology Act 2000 and Rules there under.</p>
                    <p className='text-justify'>We and our affiliates will share / sell / transfer / license / covey
                        some or all of your personal information with another business entity should we (or our assets)
                        plan to merge with or are acquired by that business entity, or re-organization, amalgamation,
                        restructuring of business or for any other reason whatsoever. Should such a transaction or
                        situation occur, the other business entity or the new combined entity will be required to follow
                        the privacy policy with respect to your personal information. Once you provide your information
                        to us, you provide such information to us and our affiliate and we and our affiliate may use
                        such information to provide you various services with respect to your transaction whether such
                        transaction are conducted on BayFay or with third party merchant's or third party merchant's
                        website.</p>
                    <p><strong>Information Collection and Use</strong></p> <p>
                        For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.
                    </p> <div><p>The app does use third party services that may collect information used to identify you.</p> <p>Link to privacy policy of third party service providers used by the app </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><li><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Fabric</a></li></ul></div> <p><strong>Log Data</strong></p> <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p> <p><strong>Cookies</strong></p> <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p> <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
                    <p><strong>Location Information</strong></p>
                    <p>Depending on the Services that you use, and your app settings or device permissions, we may collect your real time information, or approximate location information as determined through data such as GPS, IP address;</p>
                    <p><strong>Mobile Device Access</strong></p>
                    <p>We may request access or permission to certain features from your mobile device, including your mobile device’s. If you wish to change our access or permissions, you may do so in your device’s settings.</p>
                    <p><strong>Device Information</strong></p>
                    <p>We may collect information about the devices you use to access our Services, including the hardware models, operating systems and versions, software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion information and mobile network information. Analytics companies may use mobile device IDs to track your usage of the BayFay Platform;</p>
                    <p><strong>Stored information and files</strong></p>
                    <p>BayFay mobile app may also access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts and address book information. If you permit the BayFay app to access the address book on your device, we may collect names and contact information from your address book to facilitate social interactions through our services and for other purposes described in this Policy or at the time of consent or collection. If you permit the BayFay app to access the calendar on your device, we collect calendar information such as event title and description, your response (Yes, No, Maybe), date and time, location and number of attendees.</p>
                    <p><strong>Push Notifications</strong></p>
                    <p>We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</p>
                    <p><strong>Service Providers</strong></p> <p>We may employ third-party companies and individuals due to the following reasons:
                    </p><ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p> We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                    <div className='font-size-1rem font-weight-bold py-2'>Fee /Commission charge:</div>
                    <p className='text-justify'>(a) The firm as such shall not charge any fee for providing
                        platform/display on website however for all such sales that are made/generated using the website
                        BayFay a commission shall be paid by the Vendor to the Firm. The details of commissions to be
                        paid product category/type wise is specified is Schedule A to this agreement. The schedule can
                        be amended to modify any rate of commission in respect of any product. In particular, BayFay
                        may, at its sole discretion, introduce new services and modify some or all of the existing
                        services offered on the Platform. In such an event, BayFay reserves the right to introduce fees
                        for the new services offered or amend/introduce fees for existing services, as the case may be.
                        Any amendment to such schedule will be express. The said schedule is to be treated as part and
                        parcel of the agreement.</p>
                    <p className='text-justify'>(b) It is expressly agreed by the parties hereto that the Firm shall
                        debit the amount of commission from the remittance to Vendor at the time of forwarding the order
                        received from the end customer.</p>
                    <p className='text-justify'>(c) The firm will charge a commission for payments made through online
                        whereas the vendor is liable for COD.</p>
                    <p className='text-justify'>(d)The payment of order via online will be managed by the Nodal account
                        and it will be credited to vendor’s BayFay wallet between 5-7 days.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Nodal Account:</div>
                    <p className='text-justify'>Remittances to you for Your Transactions (excluding COD transactions)
                        will be made through a nodal account (the "Nodal Account") in accordance with the directions
                        issued by Reserve Bank of India for the opening and operation of accounts and settlement of
                        payments for electronic payment transactions involving intermediaries vide its notification
                        RBI/2009-10/231 DPSS.CO.PD.No.1102 / 02.14.08/ 2009-10 dated November 24, 2009. mutually agreed
                        and other means used to You You hereby agree and authorize us to collect payments on your behalf
                        from customers for any sales made through BayFay (online payment). You authorize and permit us
                        to collect and disclose any information (which may include personal or sensitive information
                        such as Your Bank Account information) made available to us in connection with this Agreement to
                        a bank, auditor, processing agency, or third party contracted by us in connection with this
                        Agreement.</p>
                    <p className='text-justify'>Subject to and without limiting any of the rights described in Section 2
                        of the General Terms, we may hold back a portion or your Sale Proceeds as a separate reserve
                        ("Reserve"). The Reserve will be in an amount as determined by us and the Reserve will be used
                        only for the purpose of settling the future claims of customers in the event of non-fulfilment
                        of delivery to the customers of your Products keeping in mind the period for refunds and
                        chargebacks.</p>
                    <p className='text-justify'>Except as otherwise stated in this Agreement (including without
                        limitation Section 2 of the General Terms), you authorize us and we will remit the Settlement
                        Amount to Your Bank Account on the Payment Date in respect of an Eligible Transaction. When you
                        either initially provide or later change Your Bank Account information, the Payment Date will be
                        deferred for a period of up to 14 calendar days. You will not have the ability to initiate or
                        cause payments to be made to you. If you refund money to a customer in connection with one of
                        Your Transactions in accordance with Section S-2.2, on the next available Designated Day for
                        BayFay, we will credit you with the amount of the Referral Fee paid by you to us attributable to
                        the amount of the customer refund, less the Refund Administration Fee for each refund, which
                        amount we may retain as an administrative fee.</p>
                    <p className='text-justify'>Vendor agrees that the transaction price paid by a buyer will be
                        remitted to a seller’s bank account contingent upon the following events:</p>
                    <p className='text-justify'>(a) Buyer confirms the delivery of products and/or services in the
                        transaction;</p>
                    <p className='text-justify'>(b) Buyer does not take any action on payment facility to confirm
                        delivery within such a time period as provided in the policies despite confirmation of dispatch
                        of products and/or services by a seller to the buyer;</p>
                    <p className='text-justify'>(c) Buyer’s refund claim is rejected by BayFay due to any breach of the
                        ToU, policies, and any applicable law; Once the transaction is completed by the Seller, the
                        remittance to seller shall be made in accordance with RBI Intermediary Guidelines.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Payment:</div>
                    <p className='text-justify'>All online bank transfers from valid bank accounts are processed using
                        the gateway provided by the respective issuing bank that supports payment facility to provide
                        these services to the users. All such online bank transfers on payment facility are also
                        governed by the terms and conditions agreed to between a seller buyer and the respective issuing
                        bank.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Charge back:</div>
                    <p className='text-justify'>In case of any chargebacks levied by the bank, BayFay shall have the
                        right to deduct such chargebacks from seller remittances, present and future, and a vendor’s
                        only remedy will be to discuss and resolve the same with the bank. A seller hereby agrees to
                        extend full co-operation in resolving the chargeback disputes raised by a buyer through the bank
                        and shall provide necessary documentation regarding the transaction to the complete satisfaction
                        of the bank. In case the chargeback is ruled against a seller, BayFay shall be entitled and
                        authorized to recover the same from the vendor to its fullest extent and the bank’s decision
                        shall be final and binding in this regard. In the event BayFay has made any excess payment to
                        seller inadvertently, such excess payments shall be set-off from any future payments payable by
                        BayFay to the seller.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>GST/Taxes:</div>
                    <p className='text-justify'>You agree to bear any and all applicable taxes, charges, cesses levied
                        thereon (including CGST+SGST/IGST/CGST+UGST and GST cess as may be applicable to the
                        transaction).</p>
                    <p className='text-justify'>The seller shall be solely liable for any liability which may be imposed
                        by taxation authorities for any discrepancy in the invoices.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Term and Termination</div>
                    <p className='text-justify'>This Agreement shall be valid and binding on both the parties until it
                        is terminated by either party by giving 60 days’ prior written notice to the other party without
                        assigning any reason whatsoever for such termination. BayFay may terminate this Agreement
                        immediately in case of breach of any of the provisions of this Agreement by the Merchant, if the
                        Merchant does not proceed to cure the breach within ten (10) days after receipt of a written
                        Notice of the breach.</p>
                    <p className='text-justify'>Termination for Bankruptcy, Insolvency, Winding Up, etc.</p>
                    <p className='text-justify'>(a)Either party may terminate this Agreement with immediate effect upon
                        written notice in the event that the other party abandons its responsibilities under this
                        Agreement, becomes bankrupt or insolvent or files any proposal or makes any assignment for the
                        Merchant of creditors, or an order is made for its winding up or a receiver is appointed for
                        substantial part of its. On termination of this Agreement, all outstanding obligations of the
                        parties under the terms of this Agreement shall continue to subsist until the same are fulfilled
                        / realized in totality.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Liability:</div>
                    <p className='text-justify'>Vendors acknowledge that BayFay will not be liable for any damages,
                        interests, claims etc. resulting from not processing a transaction/transaction price or any
                        delay in processing a transaction/transaction price that is beyond the control of BayFay.</p>
                    <p className='text-justify'>BayFay shall make payments into the bank account provided by a seller
                        during the seller registration process. Once BayFay has made payments into such a bank account
                        number, BayFay shall be discharged of any/all liabilities towards the seller and the seller
                        shall not be eligible for any claims whatsoever.</p>
                    <p className='text-justify'>In the course of your use of the Platform, you agree to furnish your
                        details and information as requested by us from time to time.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Private & Public shops registration &
                        subscription:
                    </div>
                    <p className='text-justify'>This platform is controlled and operated by BayFay where the registered
                        vendor can legally able to sell through public shop (among all other shops in the competitive
                        market) whereas in private shop, the vendor can individually project & promote their shop
                        &item(s) with their own identity through this platform. BayFay offers a free subscription
                        service to display the private shop for first 6 months. In case, the vendor is interested to
                        continue their private shop via this platform then subscription fee will be levied after 6
                        months or else they can sell their items among all other vendors only through public shop
                        (Identity of the vendor will not be displayed).Product listings may include text descriptions,
                        graphics, pictures, trademark, logo, symbols, ideas, artwork, images, other material or
                        information for sales &promotion. All items must be listed in an appropriate category on the
                        Platform. All listed items must be kept in stock for successful fulfilment of sales</p>
                    <p className='text-justify'>Vendor shall be responsible for the Content posted or transmitted on the
                        Platform by You. The Content will become our property and you grant us the royalty free and
                        transferable rights in such content.</p>
                    <p className='text-justify'>You agree that any content you post may be used by us, consistent with
                        this TOU, and you are not entitled to any payment or other compensation for such use.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Arbitration</div>
                    <p className='text-justify'>If Parties fails in endeavor to settle amicably by mutual discussion any
                        disputes, differences or claims whatsoever related to this agreement. Failing such amicable
                        settlement ,the dispute shall be settled by arbitration. The Arbitration and Conciliation Act
                        1996 shall govern the arbitration proceedings. The arbitration shall be held in Chennai,
                        Tamilnadu. The language of arbitration shall be English and the arbitral award shall be final
                        and binding on both the parties. The arbitration proceedings will be held before the sole
                        Arbitrator mutually appointed by the Parties. Any arbitration award will be final and binding on
                        the parties and the Award shall include allocation of the cost of the Arbitration Proceedings
                        .Moreover judgment there on may be entered in any court of competent jurisdiction. This
                        agreement (including its jurisdiction clause) shall be governed by, construed and take effect in
                        accordance with the laws of India. The courts of Chennai, Tamilnadu shall alone have
                        jurisdiction in all matters.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Governing Law and Jurisdiction</div>
                    <p className='text-justify'>This agreement shall be construed only in accordance with the laws of
                        India. In respect of all, Claims, matters/disputes arising out of, in connection with or in
                        relation to this Agreement, only the competent Courts at Chennai, Tamilnadu ,shall have
                        exclusive jurisdiction. No other Court shall have jurisdiction to deal with any dispute or any
                        matter between the parties arising out of this Agreement.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Force Majeure:</div>
                    <p className='text-justify'>We will not be liable for any delay or failure to perform any of our
                        obligations under this Agreement by reasons, events or other matters beyond our reasonable
                        control.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>DISCLAIMER</div>
                    <p className='text-justify'>THIS DISCLAIMER SHALL BE APPLICABLE TO ANY PERSON, COMPANY, INDIVIDUAL
                        OR ENTITY WHO HAS ENTERED INTO THIS MERCHANT AGREEMENT AND OTHER RELATED SERVICES PROVIDED
                        HEREIN. THE INFORMATION CONTAINED IN THESE TERMS AND CONDITIONS HAVE BEEN PROVIDED SPECIFICALLY
                        FOR THE PURPOSE OF OFFERING THE SERVICES MENTIONED IN THIS MERCHANT AGREEMENT AND THE SAME IS
                        ACKNOWLEDGED AND DULY SIGNED BY MERCHANT. BayFay DOES NOT HAVE ANY METHOD TO ASSESS OR VERIFY
                        THE VERACITY OF INDIVIDUAL'S OR ENTITY'S OR THIRD PARTY'S PRODCUCT AND SERVICES WHICH ARE
                        OFFERED UNDER THIS AGREEMENT. MERCNAHT FURTHER AGREE AND ACKNOWLEDGE THAT MERCHANT ALONE SHALL
                        BE HELD RESPONSIBLE FOR ANY PROFIT OR LOSS INCURRED DURING THE TERM OF THIS AGREEMENT.</p>
                    <p className='text-justify'>BayFay WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
                        USE OF THIS SITE, SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE
                        AND CONSEQUENTIAL DAMAGES. MERCHANT EXPRESSLY AGREE THAT MERCHANTUSE OFTHIS SITE IS AT
                        MERCHANTSOLE RISK.</p>

                    <div className='font-size-1rem font-weight-bold py-2'>Contact Us</div>
                    <p className='text-justify'>Contact us for any question or comments regarding platform.</p>
                    <p className='text-justify'>Email : support@bayfay.com</p>
                </div>
            </div>
        </Aux>
    );
};

export default MerchantPolicy;
